const countries = require('world-countries');


const promotedCountryCodes = ['TH', 'MY', 'SG', 'ID', 'KR', 'JP', 'TW'];

const formattedCountries = countries.map((country) => {
  const weight = promotedCountryCodes.includes(country.cca2) ? 'high' : 'low';

  return {
    value: country.cca2,
    label: country.name.common,
    flag: country.flag,
    latlng: country.latlng,
    region: country.region,
    weight: weight,  
  };
});


const countriesData = () => {
  const getAll = () => formattedCountries;

  const getPromoted = () => {
    return formattedCountries
      .filter(item => promotedCountryCodes.includes(item.value))
      .sort((c1, c2) => promotedCountryCodes.indexOf(c1.value) - promotedCountryCodes.indexOf(c2.value));
  };

  const getByValue = (value: string) => {
    return formattedCountries.find((item) => item.value === value);
  };

  return {
    getAll,
    getByValue,
    promotedCountries: getPromoted()
  };
};

export default countriesData;
