/* eslint-disable no-unused-vars */
export enum PackageHighlightOptions {
  FreeCancellation = "FREE_CANCELLATION",
  DoorToDoorTransfer = "DOOR_TO_DOOR_TRANSFER",
  MustDo = "MUST_DO",
  FamilyFriendly = "FAMILY_FRIENDLY",
  Duration = "DURATION",
  PremiumExperience = "PREMIUM_EXPERIENCE",
  DepartureGuarantee = "DEPARTURE_GUARANTEE",
  CheaperThanGatePrice = "CHEAPER_THAN_GATE_PRICE",
  NoQueue = "NO_QUEUE",
  FastDelivery = "FAST_DELIVERY",
  UseImmediately = "USE_IMMEDIATELY",
  FinalPrice = "FINAL_PRICE",
  FreeWaiting = "FREE_WAITING",
  Support247 = "SUPPORT_247",
  MostEconomical = "MOST_ECONOMICAL",
}

export enum DepartureType {
  PickUp = "PICK_UP",
  MeetUp = "MEET_UP",
}

export enum CategoryType {
  ATTRACTION = "ATTRACTION",
  TOUR = "TOUR",
  A_E = "A_E",
  TRANSPORTATION = "TRANSPORTATION",
  CONVENIENCE = "CONVENIENCE",
  CONECTIVITY = "CONNECTIVITY",
}

export enum EcomItineraryItemType {
  Transport = "TRANSPORT",
  Meal = "MEAL",
  Attraction = "ATTRACTION",
  Accommodation = "ACCOMMODATION",
  FreeTime = "FREE_TIME",
  StartPoint = "START_POINT",
  EndPoint = "END_POINT",
  Location = "LOCATION",
}

export enum EcomAdditionalInfoType {
  Text = "TEXT",
  Boolean = "BOOLEAN",
  Selection = "SELECTION",
  MultiSelection = "MULTI_SELECTION",
  TextArea = "TEXT_AREA",
  Time = "TIME",
  Date = "DATE",
  Country = "COUNTRY",
}

export enum ProductType {
  REGULAR = "REGULAR",
  COMBO = "COMBO",
}

export enum CurrencyData {
  VND = "VND",
  THB = "THB",
  USD = "USD",
}

export enum EditStatusEnum {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export enum TypePartnerChanges {
  INFO = "INFO",
  PRICE = "PRICE",
}
export enum StatusEnum {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export enum paymentMethod {
  OnepayInternational = "onepay-international",
  OnepayDomestic = "onepay-domestic",

  LakaDevmode = "dev-mode-payment-gateway",
}

export enum UnitType {
  PERSON = "PERSON",
  ADULT = "ADULT",
  CHILD = "CHILD",
  INFANT = "INFANT",
  SENIOR = "SENIOR",
  OTHER = "OTHER",
  // SIM = 'SIM',
  // FOOD = 'FOOD',
  // VEHICLE = 'VEHICLE',
  // DURATION = 'DURATION'
}

export enum TimeslotType {
  BY_DAY = "BY_DAY",
  BY_TIME = "BY_TIME",
}

export enum ValidityGroup {
  SIM = "SIM",
  TICKET = "TICKET",
  CONVENIENCE = "CONVENIENCE",
  WIFI_RENTAL = "WIFI_RENTAL",
}

export enum ValidityInputType {
  LAKA_INPUT = "LAKA_INPUT",
  USER_INPUT = "USER_INPUT",
}

export enum DeliveryType {
  PICK_UP = "PICK_UP",
  BY_MAIL = "BY_MAIL",
  AUTO_ACTIVATE = "AUTO_ACTIVATE",
}

export enum ValidityDateType {
  PARTICIPATION = "PARTICIPATION",
  ACTIVATION = "ACTIVATION",
  REDEMPTION = "REDEMPTION",
  RECEIVE = "RECEIVE",
  PICK_UP = "PICK_UP",
  OTHER = "OTHER",
}

export enum MeetingPointsType {
  PICK_UP = "PICK_UP",
  MEET_UP = "MEET_UP",
}

export enum ContactMethodType {
  OTHER = "OTHER",
}

export enum ValidityLakaInputDateType {
  PURCHASE = "PURCHASE",
  ACTIVATION = "ACTIVATION",
  RECEIVE = "RECEIVE",
  PICK_UP = "PICK_UP",
  OTHER = "OTHER",
}

export enum CancellationPolicyType {
  N_A = "",
  NO = "NO",
  FREE = "FREE",
  CONDITIONAL = "CONDITIONAL",
}

export enum CancellationPolicyEventType {
  ACTIVITY_START = "ACTIVITY_START",
  REDEMPTION = "REDEMPTION",
  OTHER = "OTHER",
}

export enum ConfirmationTimeType {
  INSTANT = "INSTANT",
  H24 = "24H",
  H48 = "48H",
}

export enum RedeemMethodType {
  LAKA = "LAKA",
  LAKA_MERCHANT = "LAKA_MERCHANT",
  MERCHANT = "MERCHANT",
}

export enum QrPeerCodeType {
  BOOKING = "QRCODE",
  UNIT_TYPE = "BARCODE",
  UNIT = "PDF",
}

export enum BookingVoucherStatus {
  PENDING = "PENDING",
  RESERVED = "RESERVED",
  CONFIRMED = "CONFIRMED",
  READY = "READY",
  RELEASED = "RELEASED",
}

export enum EcomBookingStatus {
  PENDING = "PENDING",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  CANCELLED = "CANCELLED",
}

export enum ValidityDateTypeTranslate {
  PARTICIPATION = "Ngày tham gia",
  ACTIVATION = "Ngày kích hoạt",
  REDEMPTION = "Ngày đổi vé",
  RECEIVE = "Ngày nhận",
  PICK_UP = "Ngày nhận",
  OTHER = "OTHER",
  PURCHASE = "Ngày mua",
}

export enum LinkType {
  NAME = "NAME",
  COUNTRY = "COUNTRY",
  CITY = "CITY",
  ACTIVITY_ID = "ACTIVITY_ID",
  DEPARTURE = "DEPARTURE",
}
