const vietnameseNames = {
  'Vietnam': 'Việt Nam',
  'Laos': 'Lào',
  'Cambodia': 'Campuchia',
  'Thailand': 'Thái Lan',
  'Burma': 'Myanmar',
  'Taiwan': 'Đài Loan',
  'South Korea': 'Hàn Quốc',
  'North Korea': 'Triều Tiên',
  'Russia': 'Nga',
  'Japan': 'Nhật Bản',
  'China': 'Trung Quốc',
  'India': 'Ấn Độ',
  'Mongolia': 'Mông Cổ',
  'Lebanon': 'Li-băng',
  'Saudi Arabia': 'Ả Rập Xê-út',
  'United Arab Emirates': 'Các Tiểu Vương quốc Ả Rập Thống nhất',
  'Turkey': 'Thổ Nhĩ Kỳ',
  'Cyprus': 'Síp',
  'Greece': 'Hy Lạp',
  'Italy': 'Ý',
  'France': 'Pháp',
  'Germany': 'Đức',
  'Spain': 'Tây Ban Nha',
  'Portugal': 'Bồ Đào Nha',
  'Netherlands': 'Hà Lan',
  'Belgium': 'Bỉ',
  'Switzerland': 'Thụy Sĩ',
  'Sweden': 'Thụy Điển',
  'Norway': 'Na Uy',
  'Denmark': 'Đan Mạch',
  'Finland': 'Phần Lan'
}

const userLanguageName = (englishName: string): string => {
  const userLanguageName = vietnameseNames[englishName];
  if (userLanguageName) {
    return userLanguageName;
  }
  return englishName;
};



export const I18nCountries = {
  userLanguageName
};
